<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-content">
      <!-- 关闭按钮 -->
      <button class="close-button" @click="closeModal" :disabled="isLoading">×</button>
      
      <!-- 标题区域 -->
      <h2 class="modal-title">Create new folder</h2>
      <p class="modal-subtitle">Use folders to organize notes.</p>
      
      <!-- 输入框 -->
      <input 
        type="text" 
        class="folder-input"
        placeholder="Enter folder name (Ex: Computer Science 101)"
        v-model="folderName"
        :disabled="isLoading"
      >
      
      <!-- 创建按钮 -->
      <button 
        class="create-button" 
        @click="createFolder"
        :disabled="isLoading"
      >
        <span v-if="!isLoading">Create folder</span>
        <span v-else class="loading-spinner"></span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      folderName: '',
      isLoading: false
    }
  },
  methods: {
    openModal() {
      this.visible = true
      this.folderName = ''  // 重置输入
      this.isLoading = false  // 重置加载状态
    },
    closeModal() {
      this.visible = false
      this.folderName = ''
    },
    async createFolder() {
      if (this.isLoading) return  // 防止重复提交
      
      if (this.folderName.trim() === '') {
        this.$message.error('Folder name cannot be empty')
        return
      }
      
      try {
        this.isLoading = true
        const subid = this.$cookies.get('subid')
        const response = await this.$axios.post('/api/createfolder/', {
          folder_name: this.folderName,
          subid: subid
        })
        
        if (response.data.code === 200) {
          this.$message.success('Folder created successfully')
          this.closeModal()
          this.$emit('folderCreated', response.data.data)
          
        } else {
          this.$message.error(response.data.messages)
        }
      } catch (error) {
        console.error('Error creating folder:', error)
        this.$message.error(error.response?.data?.messages || 'Failed to create folder')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
}

.modal-content {
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 580px;
  position: relative;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.7;
}

.modal-title {
  color: #fff;
  font-size: 24px;
  margin: 0 0 8px 0;
}

.modal-subtitle {
  color: #8e8e8e;
  margin: 0 0 24px 0;
  font-size: 14px;
}

.folder-input {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #3a3f55;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.folder-input::placeholder {
  color: #6b6b6b;
}

.create-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #7c5cff;
  color: white;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

.create-button:hover {
  background-color: #6a4edb;
}

/* 添加加载状态相关样式 */
.create-button:disabled {
  background-color: #5c4599;
  cursor: not-allowed;
  opacity: 0.7;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.folder-input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.close-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
