<template>
  <div class="folder-page">
    <div class="backhome" @click="$emit('backhomepage')"></div>
    <!-- 返回按钮 -->
    <div class="back-button" @click="$emit('backhomepage')">
      <i class="el-icon-arrow-left"></i>
      <span>Back</span>
    </div>

    <!-- 文件夹信息 -->
    <div class="folder-header">
      <div class="folder-icon">
        <img :src="folderimg" alt="folder" />
      </div>
      <div class="folder-info">
        <h2>{{ folder.folder_name }}</h2>
        <p>Folder created on {{ formatDateTime(folder.create_time) }}</p>
      </div>
    </div>

    <!-- 笔记列表 -->
    <div class="notes-section">
      <h3>Notes in folder</h3>

      <!-- 加载状态 -->
      <div v-if="isLoading" class="loading-state">
        <img :src="loadingGif" alt="Loading..." class="loading-gif">
        loading notes...
      </div>

      <!-- 笔记列表 -->
      <template v-else>
        <div class="note" @click="opennotes(note)" v-for="note in folderNotes" :key="note.noteid">
          <div class="badge">{{ getCodeByName(note.language) }}</div>
          <span class="note-icon">
            <img v-if="note.filetype == 'record'" :src="record_img" alt="" />
            <img v-if="note.filetype == 'audio'" :src="audio_img" alt="" />
            <img v-if="note.filetype == 'youtube'" :src="youtube_img" alt="" />
            <img v-if="note.filetype == 'pdf'" :src="upload_img" alt="" />
          </span>
          <div class="content">
            <h3 :class="{ redcolor: note.status === 3 }" class="note-title">{{ note.notename }}</h3>
            <p>Created on {{ formatDateTime(note.createtime) }}</p>
          </div>
          <span class="errorinfo" v-if="note.status === 3">Failed</span>
          <span @click.stop="openNoteSettings(note)" class="spanright">
            <i class="el-icon-more"></i>
          </span>
        </div>

        <!-- 无笔记提示 -->
        <div v-if="!folderNotes.length" class="empty-state">
          No notes in this folder
        </div>
      </template>

      <!-- 添加笔记按钮 -->
      <div class="add-notes-button" @click="showAddNotesModal">
        <i class="el-icon-plus"></i>
        Add Notes to folder
      </div>
    </div>
    <popup-note-settings ref="noteSettings" :note="currentNote" :folders="folders" @noteUpdated="handleNoteUpdated"
      @showCreateFolder="showCreateFolder" @deletenote="handleDeleteNote" />
    <!-- 添加笔记弹窗 -->
    <popup-add-notes ref="addNotesPopup" :folder="folder" @notesAdded="handleNotesAdded" />
  </div>
</template>

<script>
import PopupNoteSettings from './popups/popup_note_settings.vue';
import PopupAddNotes from './popups/popup_add_notes.vue'

export default {
  name: 'FolderPage',
  components: {
    PopupNoteSettings,
    PopupAddNotes
  },
  props: {
    folders: {
      type: Array,
      required: true
    },
    folder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      languages: [
        { code: "ZH", name: "Chinese" },
        { code: "ZH(T)", name: "Traditional Chinese (Hong Kong)" },
        { code: "EN", name: "English" },
        { code: "ES", name: "Spanish" },
        { code: "FR", name: "French" },
        { code: "DE", name: "German" },
        { code: "IT", name: "Italian" },
        { code: "JA", name: "Japanese" },
        { code: "RU", name: "Russian" },
        { code: "AR", name: "Arabic" },
        { code: "HI", name: "Hindi" },
        { code: "PT", name: "Portuguese" },
        { code: "NL", name: "Dutch" },
        { code: "KO", name: "Korean" },
        { code: "TR", name: "Turkish" },
      ],

      folderNotes: [],
      folderimg: require("@/assets/images/homepage/folder.png"),
      record_img: require("@/assets/images/index/icons/10.png"),
      audio_img: require("@/assets/images/index/icons/1.png"),
      youtube_img: require("@/assets/images/index/icons/2.png"),
      upload_img: require("@/assets/images/index/icons/3.png"),
      currentNote: null,
      isLoading: false,
      loadingGif: require("@/assets/images/noteindex/loading_img1.gif"),
    }
  },
  methods: {
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    },
    async getFolderNotes() {
      try {
        this.isLoading = true
        const subid = this.$cookies.get("subid")
        const response = await this.$axios.post(`/api/getfoldernotes/`, {
          folder_id: this.folder.folder_id,
          subid: subid
        })

        if (response.data.code === 200) {
          this.folderNotes = response.data.data.notes
        } else {
          this.$message.error(response.data.messages)
        }
      } catch (error) {
        console.error('Error fetching folder notes:', error)
        this.$message.error('Failed to load notes')
      } finally {
        this.isLoading = false
      }
    },
    opennotes(note) {
      this.$emit('opennotes', note)
    },
    openNoteSettings(note) {
      note.folder_id = this.folder.folder_id;
      this.currentNote = note;
      this.$nextTick(() => {
        this.$refs.noteSettings.openModal()
      })
    },
    getCodeByName(name) {
      const language = this.languages.find((lang) => lang.name === name);
      return language ? language.code : "EN";
    },
    handleNoteUpdated({ note, folder, action }) {
      if (action === 'move') {
        // 如果笔记被移动到其他文件夹，从当前列表中移除
        if (note.folder_id !== this.folder.folder_id) {
          this.folderNotes = this.folderNotes.filter(n => n.noteid !== note.noteid)
          // 更新当前文件夹的笔记数量
          this.$emit('folderUpdated', {
            ...this.folder,
            note_count: this.folder.note_count - 1
          })
        }

        // 如果返回了目标文件夹信息，更新目标文件夹的笔记数量
        if (folder) {
          this.$emit('folderUpdated', folder)
        }

        // 关闭设置弹窗
        this.$refs.noteSettings.closeModal()
      } else if (action === 'rename') {
        // 更新笔记标题
        this.folderNotes = this.folderNotes.map(n =>
          n.noteid === note.noteid ? { ...n, notename: note.notename } : n
        )

        if (!note.notename.trim()) {
          // this.$message.error('Note title cannot be empty')
          this.noteTitle = note.notename
          return
        }
        try {

          this.$axios.post('/api/updatenotename/', {
            noteid: note.noteid,
            notename: note.notename.trim(),
          })

        } catch (error) {
          console.error('更新笔记标题失败:', error)
        }


      }
    },
    showCreateFolder() {
      this.$emit('showCreateFolder')
    },
    handleDeleteNote(note) {
      // 从列表中移除被删除的笔记
      this.folderNotes = this.folderNotes.filter(n => n.noteid !== note.noteid)
      // 关闭设置弹窗
      this.$refs.noteSettings.closeModal()
      // 通知父组件更新文件夹信息
      this.$emit('folderUpdated', {
        ...this.folder,
        note_count: (this.folder.note_count || 0) - 1
      })
    },
    showAddNotesModal() {
      this.$refs.addNotesPopup.openModal()
    },
    handleNotesAdded({ notes, folder }) {
      // 更新文件夹笔记列表
      console.log(folder, notes);
      this.folderNotes.push(...notes)
    }
  },
  mounted() {
    this.getFolderNotes()
  }
}
</script>

<style lang="less" scoped>
.folder-page {
  padding: 3vw;
  color: #fff;
}

.backhome {
  cursor: pointer;
  width: 220px;
  height: 135px;
  // border:1px solid #fff ;
  position: absolute;
  left: 0;
  top: 0;
}

.back-button {
  position: absolute;
  top: 20px;
  left: calc(3vw + 230px);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 20px;
  transition: opacity 0.3s;
}

.back-button:hover {
  opacity: 0.8;
}

.folder-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 40px;
}

.folder-icon img {
  width: 48px;
  height: 48px;
}

.folder-info h2 {
  margin: 0;
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folder-info p {
  margin: 4px 0 0;
  color: #8e8e8e;
}

.notes-section h3 {
  margin: 0 0 16px;
  font-size: 18px;
}

.add-notes-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background-color: #2a2d3d;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.add-notes-button:hover {
  background-color: #3a3f55;
}

/* 复用 HomePage.vue 中的笔记样式 */
.note {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.6vw 1.8vw;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .badge {
    position: absolute;
    top: -0.2vw;
    left: -1.4vw;
    background-color: #6c5ce7;
    /* 角标背景色 */
    color: white;
    /* 角标文本颜色 */
    padding: 0.8vw 0 0.25vw;
    width: 4vw;
    text-align: center;
    /* 使角标完全位于左上角 */
    font-size: 0.75vw;
    font-weight: 500;
    transform: rotate(-50deg);
    /* 调整角标文字大小 */
  }

  &:last-child {
    border-bottom: none;
  }

  .note-icon {
    margin-right: 1vw;

    img {
      max-width: 2vw;
    }

    i {

      font-size: 2.5vw;
      color: #063bca;
    }
  }

  .content {
    width: 100%;

    h3 {
      margin-bottom: 5px;
      font-size: 1vw;
      color: #fff;
    }


    p {
      margin: 0;
      color: #ccc;
      font-size: 0.8rem;
    }

    .redcolor {
      color: rgb(212, 25, 25);
    }

  }

  .errorinfo {
    background-color: rgb(212, 25, 25);
    color: #fff;
    padding: 0.2vw 1vw;
    border-radius: 5px;
    margin-right: 2vw;
  }

  .spanright {
    width: 2vw;
    height: 2vw;
    border-radius: 10px;
    transition: all 0.3s;

    i {
      width: 2vw;
      height: 2vw;
      font-size: 24px;
      transform: rotate(90deg);
      color: #aaa;
      border-radius: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    &:hover {
      background-color: #090525;
    }
  }


  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.note:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}

.loading-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.loading-gif {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.empty-state {
  text-align: center;
  padding: 40px 0;
  color: #8e8e8e;
}

.note-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px; /* 根据实际需要调整 */
}
</style>