<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-content">
      <button class="close-button" @click="closeModal">×</button>
      
      <h2 class="modal-title">Add notes to folder</h2>
      <p class="modal-subtitle">Select the notes you want to add to this folder.</p>

      <!-- 笔记列表 -->
      <div class="notes-list">
        <div 
          v-for="note in availableNotes" 
          :key="note.id"
          class="note-item"
          :class="{ selected: selectedNotes.includes(note.id) }"
          @click="toggleNoteSelection(note)"
        >
          <span class="note-name">{{ note.note_name }}</span>
          <div class="checkbox">
            <input 
              type="checkbox" 
              :checked="selectedNotes.includes(note.id)"
            >
          </div>
        </div>
        <!-- 加载中状态 -->
        <div v-if="isLoading" class="loading">
          Loading...
        </div>
      </div>

      <!-- 添加按钮 -->
      <button 
        class="add-button"
        :disabled="selectedNotes.length === 0 || isAdding"
        @click="addNotesToFolder"
      >
        Add Notes
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupAddNotes',
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      availableNotes: [],
      selectedNotes: [],
      isAdding: false
    }
  },
  methods: {
    async openModal() {
      this.visible = true
      this.selectedNotes = []
      await this.fetchAvailableNotes()
    },
    closeModal() {
      this.visible = false
      this.selectedNotes = []
    },
    toggleNoteSelection(note) {
      const index = this.selectedNotes.indexOf(note.id)
      if (index === -1) {
        this.selectedNotes.push(note.id)
      } else {
        this.selectedNotes.splice(index, 1)
      }
    },
    async fetchAvailableNotes() {
      if (this.isLoading ) return

      try {
        this.isLoading = true
        const subid = this.$cookies.get('subid')
        const response = await this.$axios.post('/api/getavailablenotes/', {
          subid: subid,
          folder_id: this.folder.folder_id
        })
        
        if (response.data.code === 200) {
          this.availableNotes = response.data.notes
        } else {
          this.$message.error(response.data.messages)
        }
      } catch (error) {
        console.error('Error fetching available notes:', error)
        this.$message.error('Failed to load notes')
      } finally {
        this.isLoading = false
      }
    },
    async addNotesToFolder() {
      if (this.selectedNotes.length === 0 || this.isAdding) return

      try {
        this.isAdding = true
        const subid = this.$cookies.get('subid')
        const response = await this.$axios.post('/api/addnote/', {
          note_ids: this.selectedNotes,
          folder_id: this.folder.folder_id,
          subid: subid
        })

        if (response.data.code === 200) {
          this.$emit('notesAdded', {
            notes: response.data.data.notes,
            folder: response.data.data.folder
          })
          this.closeModal()
        } else {
          this.$message.error(response.data.messages)
        }
      } catch (error) {
        console.error('Error adding notes:', error)
        this.$message.error('Failed to add notes')
      } finally {
        this.isAdding = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1c1f2e;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 600px;
  position: relative;
}

.modal-title {
  color: #fff;
  margin: 0 0 8px;
  font-size: 24px;
}

.modal-subtitle {
  color: #8e8e8e;
  margin: 0 0 20px;
  font-size: 14px;
}

.notes-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.note-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }

  &.selected {
    background: rgba(108, 92, 231, 0.2);
  }
}

.note-name {
  color: #fff;
  font-size: 14px;
}

.checkbox {
  input {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}

.add-button {
  width: 100%;
  padding: 12px;
  background: #6c5ce7;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover:not(:disabled) {
    background: #5f4dd0;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
}
</style> 