<template>
    <div class="ad-dialog" v-if="visible">
      <div class="ad-overlay" @click="handleOverlayClick">
        <div class="ad-content">
          <img :src="imageUrl" :alt="imageAlt" class="ad-image" />
          <button class="close-btn" @click="handleClose">
            <i class="el-icon-close"></i>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdDialog',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      imageUrl: {
        type: String,
        required: true
      },
      imageAlt: {
        type: String,
        default: 'Advertisement'
      },
      closeOnClickOverlay: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:visible', false);
        this.$emit('close');
      },
      handleOverlayClick() {
        if (this.closeOnClickOverlay) {
          this.handleClose();
        }
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .ad-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
  }
  
  .ad-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ad-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 90%;
  }
  
  .ad-image {
    max-width: 500px;
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
  }
  
  .close-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    
    i {
      font-size: 30px;
      color: #666;
    }
    
    &:hover {
      background: #fff;
      transform: scale(1.1);
      
      i {
        color: #ff5733;
      }
    }
  }
  </style>