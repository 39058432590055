<template>
  <div class="turbolearn-page">
    <header>
      <div class="logo">
        <img @click="scrollToElement('turbolearn-page')" :src="index_logo" alt="Pen icon" />
      </div>
      <nav>
        <a @click="scrollToElement('container')">Features</a>
        <a @click="gopage('price')">Pricing</a>

        <a @click="gopage('about')">About us</a>
        <div class="faq-dropdown" >
          <a>FAQ</a>
          <div class="dropdown-content">
            <a @click="openFaqPage('most-popular')">Most Popular</a>
            <a @click="openFaqPage('record-notes')">Record & Notes</a>
            <a @click="openFaqPage('subscription')">subscription</a>
          </div>
        </div>
      </nav>
      <div v-if="!notlogin" @click="gohomepage()" class="dashboard-btn">
        Dashboard
      </div>
      <div v-if="notlogin" @click="gologinpage()" class="dashboard-btn">
        Get started today
      </div>
    </header>
    <main v-if="showpage">
      <PricePage v-if="showpage == 'price'" />
      <temsservice v-if="showpage == 'TermsService'" />
      <PrivacyPolicy v-if="showpage == 'PrivacyPolicy'" />
      <AboutUs v-if="showpage == 'about'" />
    </main>
    <main v-if="showpage == ''">
      <div class="features">
        <div class="content">
          <h1>
            Al-Powered <br />
            Note-Taking for a Clear Mind<!--  -->
            <!-- <span class="purple">Instant</span> notes, flashcards,
            <br />quizzes, and more. -->
          </h1>
          <p>Convert audio, video, and PDFs to organized notes instantly.</p>
          <div class="startbox" @click="gologinpage()" v-if="notlogin">
            <div class="startbtn dashboard-btn">Get Started-Free</div>
            <!-- <span class="nocredlt">No credit card required</span> -->
          </div>
        </div>
        <div class="upload-box">
          <!-- <lunboimg :images="carouselImages" :autoPlayInterval="3000" /> -->
          <youtubeview />
        </div>
      </div>
      <div class="colleges" v-if="false">
        <p>30000+ students have chosen us</p>
        <div class="carousel-container">
          <div class="universities">
            <img :src="school_1" alt="Columbia" />
            <img :src="school_2" alt="HKUST" />
            <img :src="school_3" alt="Manchester" />
            <img :src="school_4" alt="PolyU" />
            <img :src="school_5" alt="Sydney" />
            <img :src="school_6" alt="Toronto" />
            <img :src="school_7" alt="UBC" />
            <img :src="school_8" alt="UCL" />
            <img :src="school_9" alt="UCLA" />
            <img :src="school_10" alt="Washington" />
            <img :src="school_1" alt="Columbia" />
            <img :src="school_2" alt="HKUST" />
            <img :src="school_3" alt="Manchester" />
            <img :src="school_4" alt="PolyU" />
            <img :src="school_5" alt="Sydney" />
            <img :src="school_6" alt="Toronto" />
            <img :src="school_7" alt="UBC" />
            <img :src="school_8" alt="UCL" />
            <img :src="school_9" alt="UCLA" />
            <img :src="school_10" alt="Washington" />
          </div>
        </div>
      </div>
      <div class="container">
        <h1>Features</h1>
        <div class="testimonials">
          <div :class="{
            testimonial: true,
            testimonial_l: index % 3 == 0,
            testimonial_m: index % 3 == 1,
            testimonial_r: index % 3 == 2,
          }" v-for="(testimonial, index) in testimonials" :key="index">
            <!-- <div class="iconimg">
              <img :src="testimonial.iconurl" class="school-logo" />
            </div> -->
            <div class="student-info">
              <!-- <i :class="testimonial.iconurl"> </i> -->
              <span class="name">{{ testimonial.name }}</span>
              <img :src="testimonial.iconurl" class="school-logo" />
            </div>
            <p>
              <strong>{{ testimonial.content1 }}</strong>{{ testimonial.content }}
            </p>
          </div>
        </div>
      </div>
    </main>
    <div class="footer">
      <span class="footer-title">Copyright © 2024 DeepSeek AI Limited. ALL rights reserved.</span>
      <div class="lista">
        <!-- <a href="#" v-for="(link, index) in footerLinks" :key="index">{{
          link
        }}</a> -->
        <a @click="scrollToElement('turbolearn-page')">{{ footerLinks[0] }}</a>
        <!-- <a @click="scrollToElement('colleges')">{{ footerLinks[1] }}</a> -->
        <a @click="gopage('price')">{{ footerLinks[2] }}</a>
        <a @click="gopage('TermsService')">{{ footerLinks[3] }}</a>
        <a @click="gopage('PrivacyPolicy')">{{ footerLinks[4] }}</a>
      </div>
    </div>
    <popuplogin ref="pLogin" />
  </div>
</template>

<script>
import PricePage from "@/views/pages/index_page/pricePage.vue";
import temsservice from "@/views/pages/index_page/temsservice.vue";
import PrivacyPolicy from "@/views/pages/index_page/PrivacyPolicy.vue";
import AboutUs from "@/views/pages/index_page/AboutUs.vue";
import popuplogin from "@/views/popups/popup_login.vue";
// import lunboimg from "@/views/comps/lunbo_img.vue";
import youtubeview from "@/views/comps/youtube.vue";
export default {
  name: "EasynoteaiPage",
  components: {
    PricePage,
    temsservice,
    PrivacyPolicy,
    popuplogin,
    // lunboimg,
    youtubeview,
    AboutUs,
  },
  data() {
    return {
      school_1: require("../assets/images/index/Columbia.png"),
      school_2: require("../assets/images/index/HKUST.png"),
      school_3: require("../assets/images/index/Manchester.png"),
      school_4: require("../assets/images/index/PolyU.png"),
      school_5: require("../assets/images/index/Sydney.png"),
      school_6: require("../assets/images/index/Toronto.png"),
      school_7: require("../assets/images/index/UBC.png"),
      school_8: require("../assets/images/index/UCL.png"),
      school_9: require("../assets/images/index/UCLA.png"),
      school_10: require("../assets/images/index/Washington.png"),

      videoId: "b1_7R57vrQA", // 提取的视频ID
      player: null,

      carouselImages: [
        require("../assets/images/index/index_lunbo1.png"),
        require("../assets/images/index/index_lunbo2.png"),
      ],

      notlogin: true,
      index_logo: require("../assets/images/index/logo.png"),
      showpage: "",
      testimonials: [
        {
          name: "Audio Note Generation",
          content1: "Record or upload audio files:",
          content:
            " Convert your spoken words into high-quality, structured notes.",
          iconurl: require("../assets/images/index/icons/1.png"),
        },
        {
          name: "YouTube Video Note Generation",
          content1: "Add YouTube video links: ",
          content: "Generate well-organized notes from YouTube videos.",
          iconurl: require("../assets/images/index/icons/2.png"),
        },
        {
          name: "PDF Note Generation",
          content1:
            "Upload PDFs, including research papers, financial reports, and more: ",
          content:
            "Create comprehensive summaries and analyses from your PDF documents.",
          iconurl: require("../assets/images/index/icons/3.png"),
        },
        {
          name: "Quizzes",
          content1: "Generate quizzes based on your content sources:",
          content:
            " Enhance your understanding of key concepts with interactive quizzes.",
          iconurl: require("../assets/images/index/icons/4.png"),
        },
        {
          name: "Flashcard",
          content1: "Generate flashcards: ",
          content:
            "Transform your notes into flashcards for effective knowledge retention.",
          iconurl: require("../assets/images/index/icons/5.png"),
        },
        {
          name: "Ask Questions",
          content1: "Ask questions to your uploaded documents:",
          content:
            " Gain deeper insights by asking questions directly to your documents.",
          iconurl: require("../assets/images/index/icons/6.png"),
        },
        // {
        //   name: "Mind Maps",
        //   content1: "Generate mind maps from uploaded documents:",
        //   content:
        //     " Visualize and organize complex information with mind maps.",
        //   iconurl: require("../assets/images/index/icons/7.png"),
        // },
        {
          name: "Live Transcript",
          content1: "",
          content:
            " Our tool offers live transcription in multiple languages, providing real-time text-based versions of spoken content.",
          iconurl: require("../assets/images/index/icons/10.png"),
        },
        {
          name: "Summary Reports",
          content1: "Create summary reports from multiple types of documents: ",
          content:
            "Quickly grasp the main points of documents with automatically generated summaries.",
          iconurl: require("../assets/images/index/icons/8.png"),
        },
        {
          name: "Multiple Language Support",
          content1: "Translate your notes into multiple languages: ",
          content: "Communicate and share your knowledge globally.",
          iconurl: require("../assets/images/index/icons/9.png"),
        },
      ],

      footerLinks: [
        "Features",
        "Testimonials",
        "Pricing",
        "Terms of Service",
        "Privacy Policy",
      ],
    };
  },
  mounted() {
    // 在组件创建时访问路由参数
    // console.log("Route params:", this.$route);
    if (this.$route.params && this.$route.params.pagekey) {
      this.gopage(this.$route.params.pagekey);
    } else {
      this.scrollToElement('turbolearn-page')
    }
    // 如果需要将参数保存到组件的数据中
    // this.pageId = this.$route.params.id;
    // this.category = this.$route.query.category;

    // 检查登录状态
    this.checkLoginStatus();
    // this.metaInfo();
  },
  methods: {
    checkLoginStatus() {
      // 获取登录状态
      const subid = this.$cookies.get("subid");
      const ulogin = this.$cookies.get("userlogin");
      // console.log("subid:", subid);
      if (!!subid && subid != "undefined" && ulogin) {
        this.notlogin = false;
      }
    },
    gologinpage() {
      // this.$refs.pLogin.openModal();
      this.$router.push("/login");
    },
    gohomepage() {
      this.$router.push("/home");
    },
    gopage(page) {
      this.showpage = page;
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
    scrollToElement(className) {
      this.showpage = false;
      // 等待DOM更新
      this.$nextTick(() => {
        // 获取指定class的第一个元素
        const element = document.querySelector(`.${className}`);

        if (element) {
          // 获取元素的位置信息
          const rect = element.getBoundingClientRect();
          // 滚动到元素位置
          window.scrollTo({
            top: window.pageYOffset + rect.top,
            behavior: "smooth", // 平滑滚动
          });

          // 可选：给元素添加焦点
          element.focus({ preventScroll: true });
        } else {
          console.warn(`Element with class "${className}" not found.`);
        }
      });
    },
    openFaqPage(page) {
      const routes = {
        'most-popular': '/faq/most-popular',
        'record-notes': '/faq/record-notes',
        'subscription': '/faq/subscription'
      }
      window.open(routes[page], '_blank')
    }
  },
};
</script>

<style lang="less" scoped>
.turbolearn-page {
  color: #ffffff;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  max-width: 80vw;
  margin: 0px auto;

  .dashboard-btn {
    color: #ffffff;
    border: none;
    padding: 0.6vw 1.5vw;
    border-radius: 6px;
    background: rgb(108, 92, 231);
    font-weight: bold;
    cursor: pointer;
    transition: opacity 0.3s;
    font-family: Outfit;
    font-size: 0.9vw;
  }

  .dashboard-btn:hover {
    opacity: 0.8;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.8vw;

    .logo {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 1.6em;

      img {
        cursor: pointer;
        margin-right: 10px;
        width: 11vw;
      }
    }

    nav {
      width: 50vw;
      text-align: left;
      a {
        cursor: pointer;
        color: rgb(255, 255, 255);
        font-family: Outfit;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0px;
        margin: 0 1.2vw;
        padding: 10px 0;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  main {
    .features {
      margin-top: 6.8vw;
      display: flex;
      align-items: center;

      .content {
        flex: 8.4;
        text-align: left;

        h1 {
          font-size: 2.6vw;
          font-weight: 600;
          line-height: 72px;
          letter-spacing: 0px;
          text-align: left;
          word-break: keep-all;
          white-space: normal;
          font-family: Outfit;

          .purple {
            color: #6c5ce7;
          }
        }

        p {
          color: rgb(255, 255, 255);
          font-family: Outfit;
          font-size: 16.8px;
          font-weight: 500;
          line-height: 23px;
          letter-spacing: 0px;
        }
      }

      .startbox {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 28px 0px;
        width: 100%;
        position: relative;

        .startbtn {
          position: absolute;
          left: 0px;
          font-size: 1vw;
          padding: 1vw 3.5vw;
        }

        .nocredlt {
          font-size: 14px;
          color: #888;
        }
      }

      .upload-box {
        background-color: #1e1e2e;
        // padding: 1vw;
        overflow: hidden;
        border-radius: 12px;
        flex: 6;
        box-shadow: 0 0 30px rgba(80, 27, 170, 0.7);
      }
    }

    .colleges {
      text-align: center;
      margin-top: 80px;

      p {
        font-size: 18px;
        padding-top: 30px;
        margin-bottom: 38px;
        opacity: 0.7;
      }

      .carousel-container {
        width: 100%;
        /* 容器宽度 */
        overflow: hidden;
        /* 隐藏溢出的内容 */
        position: relative;
        /* 相对定位 */

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          width: 100px;
          height: 100%;
          z-index: 1;
        }

        &::before {
          left: 0;
          background: linear-gradient(to right, #000, transparent);
        }

        &::after {
          right: 0;
          background: linear-gradient(to left, #000, transparent);
        }
      }

      .universities {
        display: flex;
        /* 子元素横向排列 */
        width: max-content;
        /* 宽度自动适应内容 */
        animation: scroll 15s linear infinite;
        /* 跑马灯动画 */
        transform-style: preserve-3d;
        /* 保留3D效果 */
      }

      .universities img {
        height: 100px;
        // width: 100px;
        // background: #ccc;
        opacity: 0.7;
        border-radius: 10px;
        margin: 0px 22px;
        transform: rotateY(20deg) translateZ(100px);

        /* 3D效果 */
        &:hover {
          opacity: 1;
        }
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
          /* 从右边开始 */
        }

        100% {
          transform: translateX(-50%);
          /* 向左滚动直到完全移出视野 */
        }
      }
    }

    .container {
      color: white;
      padding: 5vw 0px;
      font-family: Arial, sans-serif;

      h1 {
        text-align: center;
        font-size: 1.7vw;
        margin: 0px;
        margin: 1vw 0px;
        font-family: Outfit;
      }

      .testimonials {
        border-radius: 12px;
        padding: 2.2vw 0.6vw;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.1vw;

        .testimonial_l {
          background: linear-gradient(270deg,
              rgb(108, 92, 231),
              rgba(138, 63, 252, 0) 100%);
        }

        .testimonial_m {
          background: rgb(108, 92, 231);
        }

        .testimonial_r {
          background: linear-gradient(90deg,
              rgb(108, 92, 231),
              rgba(108, 92, 231, 0) 100%);
        }

        .testimonial {
          position: relative;
          border-radius: 0.5vw;
          padding: 0.3vw 1vw;

          p {
            color: #fff;
            line-height: 1.2vw;
            font-size: 0.8vw;
            font-family: Outfit;

            strong {
              font-weight: bold;
            }
          }

          .student-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.7vw;

            .name {
              font-size: 1vw;
              font-weight: bold;
              display: block;
              font-family: Outfit;
            }

            img {
              max-height: 2vw;
            }
          }

          i {
            margin: 20px 0px;
            font-size: 20px;
          }
        }
      }
    }
  }

  .footer {
    margin-top: 6.6vw;
    text-align: center;
    display: flex;
    font-family: Outfit;
    flex-direction: column;

    .footer-title {
      margin-bottom: 2vw;
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0px;
    }

    .lista {
      margin-bottom: 1vw;
    }

    a {
      color: #888;
      text-decoration: none;
      margin: 0 10px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.faq-dropdown {
  position: relative;
  display: inline-block;
}

.faq-dropdown>a {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: rgba(47, 50, 65, 0.95);
  border-radius: 8px;
  padding: 10px 0;
  
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-content a {
  color: #fff;
  padding: 10px 20px;
  display: block;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s;
  white-space: nowrap;
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* 显示下拉菜单 */
.faq-dropdown:hover .dropdown-content {
  display: block;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

/* 确保导航栏中的其他链接样式保持一致 */
nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

nav a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

nav a:hover {
  color: #6c5ce7;
}
</style>
