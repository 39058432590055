<template>
    <div class="faq-page">
      <div class="faq-container">
        <h1>Subscription & Payments FAQ</h1>
        
        <div class="faq-list">
          <div v-for="(item, index) in faqItems" :key="index" class="faq-item">
            <h2>{{ item.question }}</h2>
            <div class="answer" v-html="formatAnswer(item.answer)"></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SubscriptionPage',
    data() {
      return {
        faqItems: [
          {
            question: "Get the best value with our annual plans!",
            answer: "We offer a variety of subscription plans to meet your needs. Upgrade to an annual plan and save 70% compared to monthly billing."
          },
          {
            question: "What is the Free Plan?",
            answer: "Our Free Plan lets you try out all EasyNoteAI features, but with limited usage. Upgrade to a subscription for unlimited access."
          },
          {
            question: "How to pause my subscription?",
            answer: "We don't offer a pause feature right now. However, you can cancel your subscription at any time."
          },
          {
            question: "How to end or cancel subscription?",
            answer: "If you subscribed through our website, follow these steps:<br>1. Go to \"Settings\"<br>2. Click on \"Billing Portal\"<br>3. Enter your registered email address and submit<br>4. You will receive an email from Deepseek AI Limited<br>5. Follow the instructions in the email"
          }
        ]
      }
    },
    methods: {
      formatAnswer(answer) {
        return answer.replace(/\n/g, '<br>')
      }
    }
  }
  </script>
  
  <style scoped>
  .faq-page {
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 40px 0;
  }
  
  .faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
  
  h1 {
    color: #2d3436;
    margin-bottom: 40px;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
  }
  
  .faq-item {
    margin-bottom: 35px;
    padding: 25px;
    border-radius: 12px;
    background: #f8f9fa;
    transition: all 0.3s ease;
    border-left: 4px solid #00b894;
  }

  .faq-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  }
  
  h2 {
    color: #2d3436;
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.4;
  }
  
  .answer {
    color: #636e72;
    line-height: 1.8;
    font-size: 16px;
  }
  
  .answer br + br {
    content: "";
    display: block;
    margin: 8px 0;
  }
  
  @media (max-width: 768px) {
    .faq-container {
      padding: 20px;
      margin: 0 15px;
    }
    
    h1 {
      font-size: 28px;
    }
    
    h2 {
      font-size: 20px;
    }
    
    .faq-item {
      padding: 20px;
    }
    
    .answer {
      font-size: 15px;
    }
  }
  
  .faq-item {
    animation: fadeIn 0.5s ease-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .answer strong {
    color: #00b894;
    font-weight: 600;
  }
  
  .answer {
    counter-reset: step;
  }
  
  .answer br + br::before {
    counter-increment: step;
    content: counter(step) ".";
    color: #00b894;
    font-weight: bold;
    margin-right: 8px;
  }
  </style>