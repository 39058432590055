<template>
  <div v-if="visible && folder" class="modal-overlay">
    <div class="modal-content">
      <!-- 关闭按钮 -->
      <button class="close-button" @click="closeModal" :disabled="isUpdating || isDeleting">×</button>

      <!-- 标题区域 -->
      <h2 class="modal-title">Folder settings</h2>

      <!-- 输入框标签 -->
      <label class="input-label">Folder Title</label>

      <!-- 输入框 -->
      <input type="text" class="folder-input" v-model="folderName" @input="handleNameChange"
        :disabled="isUpdating || isDeleting">

      <!-- 删除按钮 -->
      <button class="delete-button" @click="handleDelete" :disabled="isDeleting || isUpdating">
        <i class="el-icon-delete"></i>
        Delete folder
      </button>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash'

export default {
  name: 'PopupFolderSettings',
  props: {
    folder: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      visible: false,
      folderName: '',
      isUpdating: false,
      isDeleting: false,
      // 使用 debounce 防止频繁请求
      debouncedUpdate: debounce(async function () {
        await this.updateFolderName()
      }, 500)
    }
  },
  methods: {
    openModal() {
      if (!this.folder) return
      this.visible = true
      this.folderName = this.folder.folder_name
    },
    closeModal() {
      this.visible = false
    },
    handleNameChange() {
      if (this.folderName === this.folder.folder_name) return
      this.debouncedUpdate()
    },
    async updateFolderName() {
      
      this.$emit('folderUpdated', {
        ...this.folder,
        folder_name: this.folderName.trim()
      })
    },
    async handleDelete() {
      try {
        await this.$confirm(
          'Are you sure you want to delete this folder?',
          'Warning',
          {
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )

        this.isDeleting = true
        const response = await this.$axios.post('/api/deletefolder/', {
          folder_id: this.folder.folder_id,
          subid: this.$cookies.get('subid')
        })

        if (response.data.code === 200) {
          this.$emit('folderDeleted', this.folder)
          this.closeModal()
        } else {
          this.$message.error(response.data.messages)
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('Error deleting folder:', error)
          this.$message.error(error.response?.data?.messages || 'Failed to delete folder')
        }
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1c1f2e;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 580px;
  position: relative;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.7;
}

.modal-title {
  color: #fff;
  font-size: 24px;
  margin: 0 0 24px 0;
  font-weight: 500;
}

.input-label {
  display: block;
  color: #fff;
  font-size: 14px;
  margin-bottom: 8px;
}

.folder-input {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #3a3f55;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.folder-input:focus {
  outline: none;
  border-color: #7c5cff;
  box-shadow: 0 0 0 1px #7c5cff;
}

.delete-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #dc3545;
  color: white;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.delete-button:hover {
  background-color: #c82333;
}

.delete-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.delete-button i {
  font-size: 16px;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.folder-input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.close-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.close-button:hover {
  opacity: 1;
}
</style>