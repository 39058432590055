<template>
    <div class="howtouse-page">
        <div class="video-container-howtouse">
            <div class="video-wrapper">
                <YoutubePlayer :url="urlpath" />
            </div>
        </div>

        <div class="content-section">
            <div class="intro">
                <h2>Welcome to Easynote AI</h2>
                <p>Hi everyone, I'm so happy to introduce Easynote AI to you. It offers a range of features to help you organize and understand information efficiently. In this quick demo video, I'm going to show you every single feature that Easynote AI currently available to you and how to make the most of them. Let's get started!</p>
            </div>

            <div class="feature-block">
                <h3>Dashboard Overview</h3>
                <p>Hitting the Dashboard button on the right upper corner, we see the main features page, where we can record or upload audio, capture live lectures with transcripts and notes, add YouTube videos, or upload PDFs.</p>
            </div>

            <div class="feature-block">
                <h3>Audio Note Generation</h3>
                <p>Let's start with Audio Note Generation. This feature is perfect for turning spoken words into organized notes. Simply choose whether you want to record a new audio clip or upload an existing one. Once you've selected your audio, Easynote AI will quickly process it and generate a detailed, structured transcript. This is a game-changer for capturing information from lectures, meetings, or interviews.</p>
                <p>Alright, so besides taking super organized notes, Easynote can do a bunch of other cool stuff. You can chat with it! Yeah, if you got a question about your notes, Just ask away! It'll give you answers straight from your document.</p>
            </div>

            <div class="feature-block">
                <h3>Record Live Lecture</h3>
                <p>This feature lets you record lectures and generate real-time transcriptions and notes. Choose from a wide range of languages, including English, Chinese, Korean, Japanese, Russian, and German. Customize your experience by selecting the language you want to record in and the language you'd like for your notes.</p>
            </div>

            <div class="feature-block">
                <h3>YouTube Video Note Generation</h3>
                <p>If you need to get the most out of your favorite YouTube videos. Easy! Just copy and paste the link, and Easynote AI will generate detailed notes, highlighting key points and summarizing the content.</p>
            </div>

            <div class="feature-block">
                <h3>PDF Note Generation</h3>
                <p>Finally, we look into PDF Note Generation. Whether it's a research paper, a financial report, or any other PDF document, Easynote AI has you covered. Simply upload your PDF, and you'll get super clear summaries, analysis, and even extract key information.</p>
            </div>

            <div class="conclusion">
                <h3>More Than Just Notes</h3>
                <p>Beyond generating detailed notes and mind maps, EasyNoteAI can also create complete transcripts, customized quizzes, and flashcards. And the best part? Easynote AI supports over 100 languages, making it a truly global tool.</p>
                <p class="cta">Easynote AI is more than just a note-taking tool. It's your partner for learning, research, and productivity. Try it out today and experience the difference!</p>
            </div>
        </div>
    </div>
</template>

<script>
import YoutubePlayer from '@/components/YoutubePlayer.vue';
export default {
    name: 'HomeHowToUse',
    components: {
        YoutubePlayer
    },
    data() {
        return {
            urlpath: "https://www.youtube.com/watch?v=gs5iaAixjqk", // 提取的视频ID   gs5iaAixjqk
        }
    },

    methods: {
        
    }
}
</script>

<style scoped>
.howtouse-page {
    min-height: 100vh;
}
.video-container-howtouse {
    text-align: left;
    margin: 2vw;
}

.content-section {
    color: #ffffff;
    margin-left: 2vw;
}

.intro, .feature-block, .conclusion {
    max-width: 92%;
    padding: 30px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    margin-bottom: 30px;
    border-left: 4px solid #4a90e2;
    transition: all 0.3s ease;
}

.intro {
    border-left-color: #00b894;
}

.feature-block:hover, .intro:hover, .conclusion:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.08);
}

h2, h3 {
    color: #4a90e2;
    margin-bottom: 20px;
    font-weight: 600;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 24px;
    color: #00b894;
}

p {
    line-height: 1.8;
    margin-bottom: 15px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
}

.cta {
    color: #00b894;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 30px;
}

@media (max-width: 768px) {
    .content-section {
        padding: 40px 15px;
    }

    .intro, .feature-block, .conclusion {
        padding: 20px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 20px;
    }

    p {
        font-size: 15px;
    }
}

.intro, .feature-block, .conclusion {
    animation: fadeInUp 0.5s ease-out forwards;
    opacity: 0;
}

.intro { animation-delay: 0.1s; }
.feature-block:nth-child(2) { animation-delay: 0.2s; }
.feature-block:nth-child(3) { animation-delay: 0.3s; }
.feature-block:nth-child(4) { animation-delay: 0.4s; }
.feature-block:nth-child(5) { animation-delay: 0.5s; }
.conclusion { animation-delay: 0.6s; }

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
