<template>
  <div class="home-page">

    <div v-if="showhome !== ''">
      <!-- <button @click="uploadstatus= 'error'">ccc</button> -->
      <radiopage v-if="showhome == 'radio'" :uploadstatus="uploadstatus" :uploadProgress="uploadProgress"
        @backhomepage="backhomepage" @upload_audio="uploadr2_audio" @openvipchaoxian="openvipchaoxian"></radiopage>
      <relradiopage v-if="showhome == 'realradio'" :recordtitle="recordtitle" :uploadstatus="uploadstatus"
        @chooeselanguage="chooeselanguage" @chooeselanguagein="chooeselanguagein" @backhomepage="backhomepage"
        @upload_audio="update_audio_notes" @openvipchaoxian="openvipchaoxian"></relradiopage>
      <folder-page v-if="showhome == 'folder'" :folder="currentFolder" :folders="folders" @backhomepage="backhomepage"
        @opennotes="opennotes" @addNotes="showAddNotesToFolder" @folderUpdated="handleFolderUpdated"
        @showCreateFolder="showCreateFolder" />
    </div>
    <div v-else>
      <section class="new-note">
        <div class="line1">
          <h2>Creat new notes</h2>
          <p v-if="false" @click="copyemail()">Feedback: easynote_ai@hotmail.com</p>
        </div>
        <p>Select your input</p>
        <div class="note-options">
          <div class="caozuo" @click="chooesSub('audio')">
            <!-- <i class="el-icon-s-help ired"></i> -->
            <img :src="audio_img" alt="" />
            <div class="textp">
              <h3>Record or Upload Audio</h3>
              <span>Upload an audio file</span>
            </div>
            <!-- <i class="iconr el-icon-arrow-right"></i> -->
          </div>
          <div class="caozuo" @click="chooesSub('record')">
            <!-- <i class="el-icon-s-help ired"></i> -->
            <img :src="record_img" alt="" />
            <div class="textp">
              <h3>Record Live Lecture</h3>
              <span>Real time transcript</span>
            </div>
            <div class="new_jiaobiao">
              New
            </div>
            <!-- <i class="iconr el-icon-arrow-right"></i> -->
          </div>
          <div class="caozuo" @click="chooesSub('video')">
            <!-- <i class="el-icon-video-camera-solid iblue"></i> -->
            <img :src="youtube_img" alt="" />
            <div class="textp">
              <h3>YouTube Video</h3>
              <span>Paste a YouTube link</span>
            </div>
            <!-- <i class="iconr el-icon-arrow-right"></i> -->
          </div>
          <div class="caozuo" @click="chooesSub('upload')">
            <!-- <i class="el-icon-upload"></i> -->
            <img :src="upload_img" alt="" />
            <div class="textp">
              <h3>Document Upload</h3>
              <span>PDF,PPT,WORD,EXCEL,CSV,TXT</span>
            </div>
            <!-- <i class="iconr el-icon-arrow-right"></i> -->
          </div>
        </div>
      </section>
      <section class="my-notes">
        <div class="titlebox">
          <h3 class="mynote">My notes</h3>

          <div class="pagination">
            <button class="create-folder-btn" @click="showCreateFolder">
              <i class="el-icon-folder-add"></i>
              Create Folder
            </button>

            <input class="input_search" type="text" @input="changesearchnotes" v-model="searchQuery"
              placeholder="Search your history notes." />
            <el-button class="btn_search" @click="searchnotes" :disabled="issearchnotes"
              element-loading-spinner="el-icon-loading" icon="el-icon-search" circle></el-button>
          </div>
        </div>
        <div class="folder-list" v-if="folders.length > 0">
          <div class="folder-item" @click="openFolder(folder)" v-for="folder in folders" :key="folder.folder_id">
            <div class="folder-icon">
              <img :src="folderimg" alt="" />
              <span class="note-count">{{ folder.note_count }}</span>
            </div>
            <div class="folder-info">
              <h4>{{ folder.folder_name }}</h4>
            </div>
            <div class="folder-actions">
              <i class="el-icon-more" @click.stop="openFolderSettings(folder)"></i>
            </div>
          </div>
        </div>
        <div class="note" @click="opennotes(note, '')" v-show="!isloading" v-for="(note, i) in sortedNotes" :key="i">
          <div class="badge">{{ getCodeByName(note.language) }}</div>
          <span class="note-icon">
            <!-- <i class="el-icon-s-claim"></i> -->
            <img v-if="note.filetype == 'record'" :src="record_img" alt="" />
            <img v-if="note.filetype == 'audio'" :src="audio_img" alt="" />
            <img v-if="note.filetype == 'youtube'" :src="youtube_img" alt="" />
            <img v-if="note.filetype == 'pdf'" :src="upload_img" alt="" />
          </span>
          <div class="content">
            <h3 :class="{ redcolor: note.status === 3 }">
              {{ note.notename }}
            </h3>
            <p>Created on {{ formatDateTime(note.createtime) }}</p>
          </div>
          <span class="errorinfo" v-if="note.status === 3">Failed</span>
          <span @click.stop="openNoteSettings(note)" class="spanright">
            <i class="el-icon-more"></i>
          </span>
        </div>
        <div class="fenyebox" v-show=" allnotecount > pageSize">
          <el-pagination class="fenyecaozuo" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="currentPage" :page-size="pageSize" layout="prev, pager, next" :total="allnotecount">
          </el-pagination>
        </div>

        <div class="error-message" v-show="notestats == 0">
          <img class="error-icon" :src="welcomeimg" />
          <p>Welcome! Click any option above to start creating your first note.</p>
        </div>
        <div class="error-message" v-show="notestats == 1">
          <img class="error-icon" :src="nonepdficon" />
          <p>Sorry, we couldn't find this pdf file. Please try again later.</p>
        </div>
        <div class="error-message" v-show="notestats == 2">
          <img class="error-icon" :src="loadingimg" />
          <p>loading...</p>
        </div>
      </section>
    </div>

    <popupupgrade v-if="isupgrade" :upgradedata="upgradedata" />
    <popupchaoxian ref="vipchaoxian" :textcontent="vipchaoxiantext" />
    <popupchaoxian ref="uploadchaoxian" :textcontent="uploadchaoxiantext" />
    <popupchaoxian ref="recordchaoxian" :textcontent="vip_livechaoxian" />
    <popupsubject @selectclass="selectclass" ref="modal" />
    <popupRecord ref="pRecord" @setrecordtitle="setrecordtitle" @home_chooseaside="home_chooseaside"
      @chooeselanguage="chooeselanguage" :recordtool="recordtool" />
    <popupAudio ref="pAudio" @upload_audio="uploadr2_audio" @setrecordtitle="setrecordtitle"
      @chooeselanguage="chooeselanguage" @chooeselanguagein="chooeselanguagein" :uploadProgress="uploadProgress"
      :taskisloading="taskisloading" @home_chooseaside="home_chooseaside" />
    <popupVideo ref="pVideo" @chooeselanguage="chooeselanguage" @createnotevideo="createnotevideo"
      :uploadProgress="uploadProgress" :taskisloading="taskisloading" />
    <popupUpload @chooeselanguage="chooeselanguage" @upload_pdf="uploadFile" ref="pUpload"
      :uploadProgress="uploadProgress" :taskisloading_u="taskisloading_u" />
    <popup-create-folder ref="createFolderDialog" @folderCreated="handleFolderCreated" />
    <popup-folder-settings ref="folderSettings" :folder="currentFolder" @folderUpdated="handleFolderUpdated"
      @folderDeleted="handleFolderDeleted" />
    <popup-note-settings ref="noteSettings" :note="currentNote" :folders="folders" @noteUpdated="handleNoteUpdated"
      @showCreateFolder="showCreateFolder" @deletenote="handleDeleteNote" />
  </div>
</template>
<script>
import radiopage from "@/views/pages/home_page/radio_page.vue";
import relradiopage from "@/views/pages/home_page/real_radio_page.vue";
import popupAudio from "@/views/pages/home_page/popups/popup_audio.vue";
import popupVideo from "@/views/pages/home_page/popups/popup_video.vue";
import popupUpload from "@/views/pages/home_page/popups/popup_upload.vue";
import popupsubject from "@/views/pages/home_page/popups/popup_subjects.vue";
import popupupgrade from "@/views/pages/home_page/popups/popup_upgrade.vue";
import popupchaoxian from "@/views/pages/home_page/popups/popup_chaoxian.vue";
import popupRecord from "@/views/pages/home_page/popups/popup_record_tool.vue";
import PopupCreateFolder from "./popups/popup_create_folder.vue";
import PopupFolderSettings from "./popups/popup_folder_settings.vue";
import PopupNoteSettings from './popups/popup_note_settings.vue'
import FolderPage from './folder_page.vue'

export default {
  name: "HomePage",
  components: {
    radiopage,
    relradiopage,
    popupAudio,
    popupVideo,
    popupUpload,
    popupsubject,
    popupupgrade,
    popupchaoxian,
    popupRecord,
    PopupCreateFolder,
    PopupFolderSettings,
    PopupNoteSettings,
    FolderPage
  },
  props: [],
  data() {
    return {
      // realradio   radio
      showhome: "",
      folderimg: require("@/assets/images/homepage/folder.png"),
      record_img: require("@/assets/images/index/icons/10.png"),
      audio_img: require("@/assets/images/index/icons/1.png"),
      youtube_img: require("@/assets/images/index/icons/2.png"),
      upload_img: require("@/assets/images/index/icons/3.png"),
      nonepdficon: require("@/assets/images/home/none_pdf_icon.png"),
      welcomeimg: require("@/assets/images/home/welcome.png"),
      loadingimg: require("@/assets/images/noteindex/loading_img1.gif"),
      showpopupsubject: false,
      mynotes: [],
      chooseclass: "",
      chooesmodel: "",
      currentPage: 1,
      pageSize: 10,
      allnotecount: 0,
      hideonsinglepage: true,
      issearchnotes: false,
      languages: [
        { code: "ZH", name: "Chinese" },
        { code: "ZH(T)", name: "Traditional Chinese (Hong Kong)" },
        { code: "EN", name: "English" },
        { code: "ES", name: "Spanish" },
        { code: "FR", name: "French" },
        { code: "DE", name: "German" },
        { code: "IT", name: "Italian" },
        { code: "JA", name: "Japanese" },
        { code: "RU", name: "Russian" },
        { code: "AR", name: "Arabic" },
        { code: "HI", name: "Hindi" },
        { code: "PT", name: "Portuguese" },
        { code: "NL", name: "Dutch" },
        { code: "KO", name: "Korean" },
        { code: "TR", name: "Turkish" },
      ],
      uploadchaoxiantext: "You have submitted too many tasks. Please try again later.",
      vipchaoxiantext: "Your free trial is almost over. <br> Please save your recordings before upgrade to premium to avoid losing them.",
      vip_livechaoxian: "You've used up your limit for this month. <br>If you need to continue using this service, please contact <span>easynote_ai@hotmail.com</span>",

      language: "",
      languagename: "",
      languagein: "",
      languagenamein: "",

      recordtitle: "",
      searchQuery: "",

      isloading: false,
      taskisloading: false,
      taskisloading_u: false,

      selectedFile: null,
      chunkSize: 1024 * 1024, // 1MB
      uploadProgress: 0,
      uploadstatus: '',


      isupgrade: false,
      upgradedata: {
        version: "1.0.1",
        date: "2024-09-07 14:47:55",
        features: [
          "Supports URL sharing.",
        ]
      },

      folders: [], // 文件夹列表
      isLoadingFolders: false, // 加载状态

      recordtool: '',

      upload_audio_params: null,
      currentFolder: null,

      currentNote: null,

    };
  },
  mounted() {
    this.getupgrade();
    if (
      this.$route.params &&
      this.$route.params.dontgetmynotes == "backhomepage" &&
      this.mynotes.length > 0
    ) {
      console.log("dontgetmynotes");
    } else {
      this.getmynotes();
    }
    this.getminfo();
    this.getFolders(); // 添加获取文件夹的调用
    this.$cookies.set('ischilddeep', '')
  },
  computed: {

    filteredFiles() {
      // return this.mynotes.filter(file =>
      //   file.notename.toLowerCase().includes(this.searchQuery.toLowerCase())
      // );
      return this.mynotes;
    },
    sortedNotes() {
      // return [...this.filteredFiles].sort((a, b) => {
      //   return new Date(b.createtime) - new Date(a.createtime);
      // });
      return this.mynotes;
    },
    notestats() {
      if (this.isloading) return 2;
      if (this.mynotes.length == 0) return 0;
      if (this.sortedNotes.length == 0) return 1;

      return 3;
    },
  },
  methods: {
    ceshi() {
      this.$refs.uploadchaoxian.openModal();
    },
    getfolder() {

    },
    async update_audio_notes(filetype, filename, fileKey, elapsedTime, transcript = '', noteid = -1) {
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/update_audio_notes/", {
          filename: filename,
          filetype: filetype,
          filekey: fileKey,
          subject: this.chooseclass,
          languagein: this.languagein,
          language: this.languagename,
          subid: subid,
          elapsedTime: elapsedTime,
          comefrom: 'web',
          chooesmodel: this.chooesmodel,
          transcript: transcript,
          noteid: noteid
        });

        const resdata = response.data;
        if (resdata.code == 200) {
          this.$message({
            message: resdata.messages,
            type: 'success',
          });
          this.handleSuccess(resdata);
        } else {
          this.$message({
            message: resdata.messages || 'Failed to update notes',
            type: 'error',
          });
        }
      } catch (error) {
        console.error('Error occurred while updating notes:', error);
        this.$message({
          message: error.response?.data?.messages || 'An error occurred while updating notes, please try again later',
          type: 'error',
          duration: 5000
        });

        // Additional error handling logic if needed
        if (error.response?.status === 401) {
          // Handle unauthorized error
          this.$router.push('/login');
        } else if (error.code === 'ECONNABORTED') {
          // Handle request timeout
          this.$message.error('Request timed out, please check your network connection');
        }
      }
    },
    async getminfo() {
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/getminfo/", {
          subid: subid,
        });
        const resdata = response.data;
        if (resdata.code == 200) {
          // console.log(resdata);
          this.$cookies.set("m_tkn", resdata.mtype, { expires: 10 });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async uploadToR2(file) {
      try {
        // 1. 获取预签名URL
        const { data } = await this.$axios.post('/api/get_upload_url/', {
          fileName: file.name,
          fileType: file.type
        });

        if (!data.uploadUrl) {
          throw new Error('Failed to get upload URL');
        }

        // 2. 使用 XMLHttpRequest 上传文件以获取进度
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();

          // 监听上传进度
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentComplete = Math.max(2, Math.floor((event.loaded / event.total) * 100));
              console.log(`Upload progress: ${percentComplete}%`);

              // 更新上传进度
              if (percentComplete < 99) {
                this.uploadProgress = percentComplete;
              }
            }
          };

          // 上传完成
          xhr.onload = () => {
            if (xhr.status === 200) {
              resolve({
                success: true,
                fileKey: data.fileKey,
                url: data.uploadUrl.split('?')[0]
              });
            } else {
              reject(new Error(`Upload failed with status: ${xhr.status}`));
            }
          };

          // 上传错误
          xhr.onerror = () => {
            reject(new Error('Network error during upload'));
          };

          // 开始上传
          xhr.open('PUT', data.uploadUrl, true);
          xhr.setRequestHeader('Content-Type', file.type);
          xhr.send(file);
        });

      } catch (error) {
        console.error('Upload failed:', error);
        this.$message.error(`Upload failed: ${error.message}`);
        throw error;
      }
    },
    async uploadr2_audio(justment, file, name, elapsedTime) {
      this.uploadstatus = 'uploading';
      this.uploadProgress = 0;

      if (!file) return;

      // 检查网络连接状态
      if (!navigator.onLine) {
        this.uploadstatus = 'error';
        this.$message.error('No internet connection. Your recording has been saved locally.');
        return;
      }
      try {
        const uploadResult = await this.uploadToR2(file);
        if (!uploadResult.success) {
          throw new Error('Upload failed');
        }
        this.$message.success('Audio saved successfully');
        this.uploadProgress = 100;
        const filekey = uploadResult.fileKey;

        let retitle = this.recordtitle;
        let splitname = file.name;
        if (justment == 'audio') {
          retitle = name
          splitname = file.name;
        }
        if (justment == 'radio') {
          retitle = this.recordtitle;
          splitname = name;
        }

        let oname = '';
        if (splitname != '' && retitle != '' && retitle != 'undefined') {
          // 将文件名按点分割成数组
          const parts = splitname.split('.');
          // 取数组的最后一个元素作为文件扩展名
          const fileExtension = parts.pop();
          oname = retitle + '.' + fileExtension;
        } else {
          oname = file.name;
        }

        try {
          const subid = this.$cookies.get("subid");
          const response = await this.$axios.post("/api/update_audio_notes/", {
            filename: oname,
            filetype: 'audio',
            filekey: filekey,
            subject: this.chooseclass,
            languagein: this.languagein,
            language: this.languagename,
            subid: subid,
            elapsedTime: elapsedTime,
            comefrom: 'web',
            chooesmodel: this.chooesmodel,
          });

          const resdata = response.data;
          if (resdata.code == 200) {
            this.$message({
              message: resdata.messages,
              type: 'success',
            });
            this.handleSuccess(resdata);
          } else {
            this.$message({
              message: resdata.messages || 'Failed to update notes',
              type: 'error',
            });
          }
        } catch (error) {
          console.error('Error occurred while updating notes:', error);
          this.$message({
            message: error.response?.data?.messages || 'An error occurred while updating notes, please try again later',
            type: 'error',
            duration: 5000
          });

          // Additional error handling logic if needed
          if (error.response?.status === 401) {
            // Handle unauthorized error
            this.$router.push('/login');
          } else if (error.code === 'ECONNABORTED') {
            // Handle request timeout
            this.$message.error('Request timed out, please check your network connection');
          }
        }

      } catch (error) {
        console.error('Upload error:', error);
        this.uploadstatus = 'error';
        this.$message.error('Upload failed. Your recording has been saved locally.');
        return;
      }
    },
    async startUpload(justment, ufile, name, elapsedTime, transcript = '', noteid = -1) {
      const subid = this.$cookies.get("subid");
      this.uploadstatus = 'uploading';
      this.uploadProgress = 0;
      if (!ufile) return;

      let retitle = this.recordtitle;
      let splitname = ufile.name;

      if (justment == 'audio') {
        retitle = name
        splitname = ufile.name;
      }
      if (justment == 'radio') {
        retitle = this.recordtitle;
        splitname = name;
      }

      let oname = '';

      if (splitname != '' && retitle != '' && retitle != 'undefined') {
        // 将文件名按点分割成数组
        const parts = splitname.split('.');
        // 取数组的最后一个元素作为文件扩展名
        const fileExtension = parts.pop();
        oname = retitle + '.' + fileExtension;
      } else {
        oname = ufile.name;
      }

      const totalChunks = Math.ceil(ufile.size / this.chunkSize);
      let uploadedChunks = 0;
      const timefilename = Date.now();
      for (let i = 0; i < totalChunks; i++) {
        const start = i * this.chunkSize;
        const end = Math.min(ufile.size, start + this.chunkSize);
        const chunk = ufile.slice(start, end);

        const formData = new FormData();
        formData.append('file', chunk, oname);
        formData.append('chunk_number', i);
        formData.append('total_chunks', totalChunks);
        formData.append('timefilename', timefilename);
        formData.append('subid', subid);

        try {
          const redata = await this.$axios.post('/api/upload_chunk/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          uploadedChunks++;
          this.uploadProgress = Math.round((uploadedChunks / totalChunks) * 100);

          if (redata.data.code == 200) {
            console.log("upload chunk success");
          }
          if (redata.data.code == 401) {
            this.$message({
              message: redata.data.messages,
              type: 'error',
            });
            this.$refs.uploadchaoxian.openModal();
            // break;
            uploadedChunks = 0;
            this.uploadProgress = 0;
            this.uploadstatus = 'isuploading';
            break;
          }
        } catch (error) {
          console.error('上传分片失败:', error);
          // 重试
          i--;
          if (i < totalChunks - 1) continue;
        }
      }
      if (this.uploadstatus == 'isuploading') {
        return;
      }


      if (uploadedChunks == totalChunks) {
        try {
          // 通知后端所有分片已上传完成
          const uploadover = await this.$axios.post('/api/complete_upload/', {
            filename: oname,
            total_chunks: totalChunks,
            subject: this.chooseclass,
            languagein: this.languagein,
            language: this.languagename,
            subid: subid,
            timefilename: timefilename,
            elapsedTime: elapsedTime,
            comefrom: 'web',
            chooesmodel: this.chooesmodel,
            transcript: transcript,
            noteid: noteid
          }, {
            timeout: 90000, // 设置超时时间为 60 秒
          });

          const redata = uploadover.data;

          this.uploadstatus = 'success';
          this.handleSuccess(redata);

        } catch (error) {
          this.handleError(error);

        }
      } else {
        this.$message({
          message: "Upload failed, please try again",
          type: 'error',
        });
      }

    },
    copyemail() {
      const emailfeed = "easynote_ai@hotmail.com";
      const input = document.createElement("input");
      input.value = emailfeed;

      // 将 input 元素添加到文档中
      document.body.appendChild(input);

      // 选中 input 中的内容
      input.select();

      // 执行复制命令
      document.execCommand("copy");

      // 移除临时的 input 元素
      document.body.removeChild(input);

      // 提示用户复制成功
      this.$message({
        message: "Mailbox copied to clipboard",
        type: "success",
      });
    },
    chooeselanguage(language, languagename) {
      this.language = language;
      this.languagename = languagename;
      // console.log(this.language, this.languagename, "homepage");
    },
    chooeselanguagein(language, languagename) {
      this.languagein = language;
      this.languagenamein = languagename;
      // console.log(this.languagein, this.languagenamein, "homepage_in");
    },
    setrecordtitle(title) {
      this.recordtitle = title;
      // console.log(this.recordtitle, "homepage_title");
    },
    async getmynotes() {
      this.isloading = true;
      this.issearchnotes = true;
      //获用户笔记
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/getmynotes/", {
          subid: subid,
          currentpage: this.currentPage,
          pagesize: this.pageSize,
          searchtext: this.searchQuery,
        });
        const resdata = response.data;
        this.isloading = false;
        this.issearchnotes = false;
        if (resdata.code == 200) {
          this.mynotes = resdata.mynotes;
          this.allnotecount = resdata.total;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getmynotes();
    },
    searchnotes() {
      this.currentPage = 1;
      this.getmynotes();
    },
    changesearchnotes() {
      if (this.searchQuery == '') {
        this.currentPage = 1;
        this.getmynotes();
      }
    },
    backhomepage() {
      this.showhome = "";
      this.getmynotes();
      this.getFolders();
    },
    selectclass(item) {
      this.chooseclass = item.title;
      // 创建一个映射对象，用于根据chooesmodel的值找到对应的组件引用
      const modalRefs = {
        audio: this.$refs.pAudio,
        video: this.$refs.pVideo,
        upload: this.$refs.pUpload,
      };
      // 使用chooesmodel的值从映射对象中查找对应的组件引用，并调用其openModal方法
      // console.log(item, this.chooesmodel, modalRefs[this.chooesmodel]);
      if (modalRefs[this.chooesmodel]) {
        modalRefs[this.chooesmodel].openModal();
      }
    },
    async chooesSub(model) {

      const subid = this.$cookies.get("subid");
      const uploadisok = await this.$axios.post('/api/uploadisok/', {
        subid: subid,
        model: model,
      });
      const resdata = uploadisok.data;
      if (resdata.code != 200) {

        if (resdata.code == 303 && resdata.mtype > 0) {
          // this.$message({
          //   type: "error",
          //   message: resdata.messages_v,
          // });
          this.$refs.recordchaoxian.openModal();
        } else if (resdata.code == 301) {
          this.$message({
            type: "error",
            message: resdata.messages,
          });
          // this.gopaypage();
        } else {
          this.gopaypage();
        }
        return;
      }

      this.chooesmodel = model;
      if (this.chooesmodel == "record") {
        this.recordtool = 'title'
        this.$refs.pRecord.openModal();
      } else {
        this.taskisloading = false;
        this.$refs.modal.openModal();
      }

    },
    opennotes(note, mode = '') {
      this.$router.push({
        name: "notesPage",
        params: { note: note, mode: mode },
      });
    },
    home_chooseaside(key) {
      // console.log("home_chooseaside", key);
      this.showhome = key;
    },
    async createnotevideo(linksrc) {
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/createnotevideo/", {
          linksrc: linksrc,
          subject: this.chooseclass,
          subid: subid,
          language: this.languagename,
        });
        this.handleSuccess(response.data);
      } catch (error) {
        this.$message.error("Note creation failed.");
        console.error("Error:", error);
      }
    },
    async uploadFile(file, name) {
      const formData = new FormData();
      if (name) {
        formData.append("file", file, name);
      } else {
        formData.append("file", file);
      }
      formData.append("subject", this.chooseclass);
      formData.append("language", this.languagename);
      const subid = this.$cookies.get("subid");
      formData.append("subid", subid);
      try {
        const response = await this.$axios.post("/api/upload/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.handleSuccess(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },
    gopaypage() {
      this.$router.push({
        name: "IndexPage", // 目标路由的名称
        params: { pagekey: "price" }, // 通过 params 传递参数
        // query: { category: "electronics" }, // 通过 query 传递参数
      });
    },
    handleSuccess(response) {
      if (response.code == 401) {
        this.$message.error(response.messages);
        this.taskisloading = false;
        this.taskisloading_u = false;
        return;
      }
      if (response.code == 203) {
        this.$message.error(response.messages);
        this.uploadstatus = 'error';
        return;
      }
      if (response.code == 201) {
        this.taskisloading = false;
        this.taskisloading_u = false;
        this.$message.error(response.messages);
        this.uploadstatus = 'error';
        return;
      }
      if (response.code == 205) {
        this.taskisloading = false;
        this.taskisloading_u = false;
        this.uploadstatus = 'error';
        this.$refs.uploadchaoxian.openModal();
        return;
      }
      if (response.code == 303) {
        this.$message({
          message: response.messages,
          type: 'error',
          duration: 6000 // 5000 毫秒（5秒）
        });
        this.$refs.vipchaoxian.openModal();
        return;
      }

      const noteid = response.noteid;
      if (!noteid) {
        this.$message({
          message: "Note creation failed. Please save the recording file in time!",
          type: 'error',
          duration: 6000 // 5000 毫秒（5秒）
        });
        this.uploadstatus = 'error';
        return;
      }

      this.$message.success("File uploaded successfully!");
      // 上传成功
      // 关闭弹窗
      this.$refs.pAudio.closeModal();
      this.$refs.pVideo.closeModal();
      this.$refs.pUpload.closeModal();
      this.showhome = "";
      // 更新笔记列表
      // const resnote = { title: "测试笔记名称", date: "Jul 9, 2024" };
      // this.mynotes.push(resnote);
      console.log("Response:", response);
      try {
        this.$router.push({
          name: "notesPage",
          params: {
            noteid: noteid,
            status: 10
          },
        }).catch(err => {
          console.error("Router error:", err);
          // 备用跳转方案
          this.$router.push({
            path: '/notes',
            query: {
              noteid: noteid,
              status: 10
            }
          });
        });
      } catch (err) {
        console.error("Navigation failed:", err);
        this.$message.error("页面跳转失败,请重试");
      }
    },
    handleError(error) {
      alert("File upload failed.");
      this.uploadstatus = 'error';
      console.error("Error:", error);
    },
    getCodeByName(name) {
      const language = this.languages.find((lang) => lang.name === name);
      return language ? language.code : "EN";
    },
    formatDateTime_folder(dateTime) {
      const date = new Date(dateTime);
      const options = {
        weekday: "long", // 添加星期几
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleString("en-US", options);
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const options = {
        weekday: "long", // 添加星期几
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      return date.toLocaleString("en-US", options);
    },
    openvipchaoxian() {
      this.$refs.vipchaoxian.openModal();
    },
    async getupgrade() {
      try {
        const response = await this.$axios.post("/api/getupgrade/", {});
        const resdata = response.data;
        if (resdata.code == 200) {
          const getupgrade = resdata.data;
          const nowwversion = localStorage.getItem("version");
          if (getupgrade.version != nowwversion) {
            localStorage.setItem("version", getupgrade.version);
            this.upgradedata = getupgrade;
            this.isupgrade = true;
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    showCreateFolder() {
      this.$refs.createFolderDialog.openModal();
    },
    handleFolderCreated(folderData) {
      // 更新文件夹列表
      // console.log(folderData, "folderData");
      this.folders = folderData;
    },
    async openFolder(folder) {
      this.currentFolder = folder
      this.showhome = 'folder'
      // 记录当前状态，用于返回
      this.$cookies.set('ischilddeep', 'folder')
    },
    async getFolders() {
      try {
        this.isLoadingFolders = true;
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/getfolders/", {
          subid: subid
        });

        if (response.data.code === 200) {
          this.folders = response.data.data.folders;
        } else {
          this.$message({
            message: response.data.messages || 'Failed to get folders',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error getting folders:', error);
        this.$message({
          message: error.response?.data?.messages || 'An error occurred while getting folders',
          type: 'error',
          duration: 5000
        });
      } finally {
        this.isLoadingFolders = false;
      }
    },
    openFolderSettings(folder) {
      if (!folder) return
      this.currentFolder = { ...folder }
      this.$nextTick(() => {
        this.$refs.folderSettings.openModal()
      })
    },
    handleFolderUpdated(folder) {
      this.folders = this.folders.map(f => f.folder_id === folder.folder_id ? folder : f);

      if (!folder.folder_name.trim()) {
        this.folderName = folder.folder_name
        return
      }
      try {
        this.$axios.post('/api/renamefolder/', {
          folder_id: folder.folder_id,
          folder_name: folder.folder_name,
          subid: this.$cookies.get('subid')
        })
      } catch (error) {
        this.$message.error(error.response?.data?.messages || 'Failed to update folder name')
      }

    },
    handleFolderDeleted(folder) {
      this.folders = this.folders.filter(f => f.folder_id !== folder.folder_id);
      this.getmynotes();
    },
    openNoteSettings(note) {
      this.currentNote = note;
      this.$nextTick(() => {
        this.$refs.noteSettings.openModal()
      })
    },
    handleNoteUpdated({ note, folder, action }) {
      if (action === 'move') {
        if (!note.folder_id) {
          // 如果笔记被移出文件夹，添加到主页列表
          this.mynotes.unshift(note)
        } else {
          // 如果笔记被移入文件夹，从主页列表移除
          this.mynotes = this.mynotes.filter(n => n.noteid !== note.noteid)
        }
        // 更新文件夹列表
        if (folder) {
          const folderIndex = this.folders.findIndex(f => f.folder_id === folder.folder_id)
          if (folderIndex !== -1) {
            this.$set(this.folders, folderIndex, folder)
          }
          this.getFolders()
        }
        // 关闭设置弹窗
        this.$refs.noteSettings.closeModal()
      } else if (action === 'rename') {
        // 更新笔记标题
        this.mynotes = this.mynotes.map(n =>
          n.noteid === note.noteid ? { ...n, notename: note.notename } : n
        )

        if (!note.notename.trim()) {
          // this.$message.error('Note title cannot be empty')
          this.noteTitle = note.notename
          return
        }

        try {

          this.$axios.post('/api/updatenotename/', {
            noteid: note.noteid,
            notename: note.notename.trim(),
          })

        } catch (error) {
          console.error('更新笔记标题失败:', error)
        }
      }


    },
    handleDeleteNote() {
      this.getmynotes();
    },
    showAddNotesToFolder() {
      // 这里可以实现添加笔记到文件夹的功能
      // 可以打开一个选择笔记的弹窗
      console.log('Add notes to folder')
    }
  },
};
</script>
<style lang="less">
@import "@/assets/styles/hompage.less";

@primary-color: rgba(255, 255, 255, 0.2);
@background-color: rgba(255, 255, 255, 0.1);
@text-color: #ffffff;
@active-text-color: #ddd;

.fenyebox {
  text-align: center;

  .fenyecaozuo {
    padding: 4px 8px;
    border-radius: 5px;
    background-color: @background-color;
    display: inline-flex !important;

    el-pagination__total,
    .el-pagination__sizes,
    .el-pagination__jump {
      color: @text-color;
    }

    .btn-prev,
    .btn-next {
      background-color: transparent !important;
      color: @text-color !important;
    }

    .btn-prev:disabled,
    .btn-next:disabled {
      background-color: @primary-color !important;
    }

    .el-pager {
      li {
        background-color: transparent;
        color: @text-color;
        font-size: 0.7vw;

        &.active {
          background-color: @background-color;
          color: @active-text-color;
        }
      }
    }


    .el-pagination__prev,
    .el-pagination__next,
    .el-input__inner {
      background-color: transparent;
      color: @text-color;

      &:hover {
        background-color: @primary-color;
        color: @active-text-color;
      }
    }

  }
}

.pagination {
  display: flex;
  align-items: center;
  gap: 10px;

  .create-folder-btn {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .el-icon-folder-add {
      margin-right: 4px;
      font-size: 14px;
    }
  }
}

.folder-list {
  margin: 20px 0;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.folder-item {
  display: flex;
  align-items: center;
  padding: 12px 12px 8px 12px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
  }

  .folder-icon {
    position: relative;
    margin-right: 15px;

    i {
      font-size: 24px;
      color: #6c5ce7;
    }

    img {
      width: 34px;
      height: 34px;
    }

    .note-count {
      position: absolute;
      top: -8px;
      right: -8px;
      background: #6c5ce7;
      color: white;
      font-size: 11px;
      padding: 2px;
      border-radius: 10px;
      width: 15px;
      height: 15px;
      text-align: center;
    }
  }

  .folder-info {
    flex: 1;
    min-width: 0;

    h4 {
      margin: 0;
      font-size: 16px;
      color: #2d3436;
      margin-bottom: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;

    }

    p {
      margin: 0;
      font-size: 12px;
      color: #636e72;
    }
  }

  .folder-actions {
    margin-left: 10px;

    i {
      font-size: 18px;
      color: #b2bec3;
      padding: 8px;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        color: #6c5ce7;
        background-color: #ddd;
      }
    }
  }
}

:deep(.el-dropdown-menu__item.danger) {
  color: #ff4757;
}

.folder-info h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  /* 根据实际需要调整 */
  margin: 0;
}

.note .content h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  /* 根据实际需要调整 */
}
</style>
