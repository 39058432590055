<template>
  <div v-if="visible && note" class="modal-overlay">
    <div class="modal-content">
      <button class="close-button" @click="closeModal" :disabled="isUpdating">×</button>

      <h2 class="modal-title">Note settings</h2>

      <!-- 笔记标题 -->
      <div class="input-group">
        <label class="input-label">Note Title</label>
        <input type="text" class="note-input" v-model="noteTitle" @input="handleTitleChange" :disabled="isUpdating">
      </div>

      <!-- 文件夹选择 -->
      <div class="input-group">
        <label class="input-label">Add note to folder</label>
        <div class="folder-selector" @click="toggleFolderDropdown">
          <div class="select-button">
            <i class="el-icon-folder"></i>
            <span class="folder-name">{{ currentFolderName }}</span>
          </div>
          <div v-if="showFolderDropdown" class="folder-dropdown">
            <div class="create-folder" @click="showCreateFolder">
              <i class="el-icon-plus"></i>
              <span class="folder-name">Create new folder</span>
            </div>
            <div v-if="note.folder_id" class="folder-item" @click="selectFolder(null)">
              <span class="folder-name">All notes</span>
              <i class="el-icon-folder-opened"></i>
            </div>
            <div v-for="folder in availableFolders" :key="folder.folder_id" class="folder-item"
              @click="selectFolder(folder)">
              <span class="folder-name">{{ folder.folder_name }}</span>
              <span class="note-count">({{ folder.note_count }})</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 导出笔记 -->
      <div class="input-group" v-if="false">
        <label class="input-label">Export note</label>
        <button class="export-button" @click="exportNote">
          <i class="el-icon-download"></i>
          Export as PDF
        </button>
      </div>

      <!-- 删除按钮 -->
      <button class="delete-button" @click="handleDelete" :disabled="isDeleting">
        <i class="el-icon-delete"></i>
        <span v-if="!isDeleting">Delete note</span>
        <span v-else class="loading-spinner"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'PopupNoteSettings',
  props: {
    note: {
      type: Object,
      required: false,
      default: null
    },
    folders: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      noteTitle: '',
      isUpdating: false,
      isDeleting: false,
      showFolderDropdown: false,
      debouncedUpdate: null
    }
  },
  created() {
    this.debouncedUpdate = debounce(this.updateNoteTitle, 500)
  },
  mounted() {
    // 添加全局点击事件监听器
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器
    document.removeEventListener('click', this.handleClickOutside)
  },
  computed: {
    // 获取当前文件夹名称
    currentFolderName() {
      if (!this.note) return 'Select folder'
      console.log(this.note);
      // 如果笔记在文件夹中
      if (this.note.folder_id) {
        const currentFolder = this.folders.find(f => f.folder_id === this.note.folder_id)
        return currentFolder ? currentFolder.folder_name : 'Select folder'
      }

      return 'Select folder'
    },

    // 过滤可选文件夹列表
    availableFolders() {
      if (!this.note) return this.folders

      // 过滤掉当前笔记所在的文件夹
      return this.folders.filter(folder => {
        return folder.folder_id !== this.note.folder_id
      })
    }
  },
  methods: {
    openModal() {
      if (!this.note) return
      this.visible = true
      this.noteTitle = this.note.notename
      this.showFolderDropdown = false

      this.$nextTick(() => {
        if (this.$refs.titleInput) {
          this.$refs.titleInput.focus()
        }
      })
    },
    closeModal() {
      this.visible = false
      this.showFolderDropdown = false
    },
    toggleFolderDropdown(event) {
      if (this.isUpdating) return
      // 阻止事件冒泡，避免触发外部点击事件
      event.stopPropagation()
      this.showFolderDropdown = !this.showFolderDropdown
    },
    showCreateFolder() {
      this.showFolderDropdown = false
      this.$emit('showCreateFolder')
    },
    async selectFolder(folder) {
      if (!this.note) return

      try {
        const response = await this.$axios.post('/api/updatenotefolder/', {
          note_id: this.note.noteid,
          folder_id: folder ? folder.folder_id : null,
          subid: this.$cookies.get('subid')
        })

        if (response.data.code === 200) {
          this.$message.success(folder ? 'Note moved to folder' : 'Note removed from folder')

          // 确保发送完整的更新信息
          this.$emit('noteUpdated', {
            note: {
              ...this.note,
              folder_id: folder ? folder.folder_id : null
            },
            // 从响应中获取更新后的文件夹信息，包含最新的笔记数量
            folder: response.data.data.folder,
            action: 'move'
          })

          this.showFolderDropdown = false
        } else {
          this.$message.error(response.data.messages)
        }
      } catch (error) {
        console.error('Error updating note folder:', error)
        this.$message.error('Failed to update note folder')
      }
    },
    handleTitleChange() {
      if (this.noteTitle === this.note.notename) return
      this.debouncedUpdate()
    },
    handleBlur(event) {
      event.target.focus()
    },
    async updateNoteTitle() {
    
      this.$emit('noteUpdated', {
        note: {
          ...this.note,
          notename: this.noteTitle.trim()
        },
        action: 'rename'
      })
    },
    async handleDelete() {
      try {
        // 添加确认对话框
        await this.$confirm(
          'Are you sure you want to delete this note?',
          'Warning',
          {
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )

        this.isDeleting = true
        const response = await this.$axios.post("/api/deletenote/", {
          noteid: this.note.noteid,
          subid: this.$cookies.get("subid"),
        });

        if (response.data.code === 200) {
          this.$message.success('Note deleted successfully')
          // 发送删除事件到父组件
          this.$emit('deletenote', this.note)
          this.closeModal()
        } else {
          this.$message.error(response.data.messages || 'Failed to delete note')
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('Error deleting note:', error)
          this.$message.error(error.response?.data?.messages || 'Failed to delete note')
        }
      } finally {
        this.isDeleting = false
      }
    },
    exportNote() {
      // 实现导出笔记的逻辑
      console.log("导出笔记", this.note);

    },
    handleClickOutside(event) {
      // 确保组件已经挂载且可见
      if (!this.$el || !this.showFolderDropdown) return

      try {
        // 获取下拉框和触发按钮的DOM元素
        const dropdown = this.$el.querySelector('.folder-dropdown')
        const button = this.$el.querySelector('.folder-selector')

        // 检查点击是否在下拉框或触发按钮之外
        if (dropdown && button &&
          !dropdown.contains(event.target) &&
          !button.contains(event.target)) {
          this.showFolderDropdown = false
        }
      } catch (error) {
        console.error('Error in handleClickOutside:', error)
        // 发生错误时关闭下拉框
        this.showFolderDropdown = false
      }
    },
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1c1f2e;
  border-radius: 16px;
  padding: 24px;
  width: 90%;
  max-width: 580px;
  position: relative;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
  background: none;
  border: none;
  color: #8e8e8e;
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.close-button:hover {
  color: #fff;
}

.modal-title {
  color: #fff;
  font-size: 24px;
  margin: 0 0 24px 0;
  font-weight: 500;
}

.input-group {
  margin-bottom: 20px;
}

.input-label {
  display: block;
  color: #8e8e8e;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}

.note-input {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #2a2d3d;
  background-color: #1c1f2e;
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.note-input:focus {
  outline: none;
  border-color: #7c5cff;
  box-shadow: 0 0 0 1px #7c5cff;
}

.folder-selector {
  width: calc(100% - 30px);
  position: relative;
}

.select-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #2a2d3d;
  background-color: #1c1f2e;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  justify-content: space-between;
  i{
    margin-right: 8px;
  }
}

.select-button:hover {
  border-color: #3a3f55;
}

.folder-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 30%;
  top: 50%;
  width: 200px;
  background: #2a2d3d;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.create-folder {
  padding: 12px 16px;
  color: #7c5cff;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-bottom: 1px solid #3a3f55;
  transition: background-color 0.3s ease;
  justify-content: space-between;
}

.folder-item {
  padding: 12px 16px;
  color: #fff;
  cursor: pointer;
  background-color: #1c1f2e;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.folder-item:hover,
.create-folder:hover {
  background: #3a3f55;
}

.export-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #2a2d3d;
  background-color: #1c1f2e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 500;
}

.export-button:hover {
  border-color: #3a3f55;
  background-color: #2a2d3d;
}

.delete-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #dc3545;
  color: white;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.delete-button:hover:not(:disabled) {
  background-color: #c82333;
}

.delete-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* 添加图标样式 */
.el-icon-folder,
.el-icon-plus,
.el-icon-download,
.el-icon-delete {
  font-size: 16px;
}

/* 添加滚动条样式 */
.folder-dropdown {
  max-height: 200px;
  overflow-y: auto;
}

.folder-dropdown::-webkit-scrollbar {
  width: 6px;
}

.folder-dropdown::-webkit-scrollbar-track {
  background: #1c1f2e;
  border-radius: 3px;
}

.folder-dropdown::-webkit-scrollbar-thumb {
  background: #3a3f55;
  border-radius: 3px;
}

.folder-dropdown::-webkit-scrollbar-thumb:hover {
  background: #4a4f65;
}

.note-count {
  color: #8e8e8e;
  font-size: 12px;
  margin-left: 4px;
}

.folder-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* 根据实际需要调整 */
}
</style>