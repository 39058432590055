import { render, staticRenderFns } from "./popup_note_settings.vue?vue&type=template&id=214be48f&scoped=true"
import script from "./popup_note_settings.vue?vue&type=script&lang=js"
export * from "./popup_note_settings.vue?vue&type=script&lang=js"
import style0 from "./popup_note_settings.vue?vue&type=style&index=0&id=214be48f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "214be48f",
  null
  
)

export default component.exports